import React, { useState, useRef } from "react";
import { APPLY_FORM_URL } from "../constants";
import logo from "../assets/logos/sp_logo.png";

const navigation = [
  { name: "Home", href: "/" },
  {
    name: "Courses",
    href: "/courses",
    subPages: [
      { name: "Adult Care", href: "/courses/adult-care" },
      { name: "AET and CAVA", href: "/courses/aet-and-cava" },
      {
        name: "Business Administration",
        href: "/courses/business-administration",
      },
      { name: "Customer Service", href: "/courses/customer-service" },
      { name: "Childcare", href: "/courses/childcare" },
      { name: "Functional Skills", href: "/courses/functional-skills" },
      { name: "First Aid", href: "/courses/first-aid" },
      {
        name: "Health and Social Care",
        href: "/courses/health-and-socialcare",
      },
      { name: "IELTS/B1", href: "/courses/ielts" },
      {
        name: "Information Technology",
        href: "/courses/information-technology",
      },
    ],
  },
  { name: "About Us", href: "/aboutus" },
  { name: "Contact", href: "/contact" },
];

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const dropdownTimeout = useRef(null);

  const handleMouseEnter = () => {
    if (dropdownTimeout.current) {
      clearTimeout(dropdownTimeout.current);
    }
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    dropdownTimeout.current = setTimeout(() => {
      setDropdownVisible(false);
    }, 500); // Adjust the delay (in milliseconds) as needed
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleMobileDropdown = (e) => {
    e.preventDefault();
    setMobileDropdownOpen(!mobileDropdownOpen);
  };

  const renderDropdownItems = (subPages) => {
    return subPages.map((sub) => (
      <a
        key={sub.name}
        href={sub.href}
        className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
      >
        {sub.name}
      </a>
    ));
  };

  const renderNavItem = (item, isMobile = false) => {
    if (item.subPages) {
      return (
        <div
          key={item.name}
          className="relative"
          onMouseEnter={!isMobile ? handleMouseEnter : undefined}
          onMouseLeave={!isMobile ? handleMouseLeave : undefined}
        >
          <div
            className={`${
              isMobile
                ? "w-full text-left text-gray-800 hover:text-blue-600 block items-center justify-between"
                : "text-gray-800 hover:text-blue-600 inline-flex items-center"
            }`}
          >
            <a href={item.href} className="flex-1">
              {item.name}
            </a>
            <svg
              onClick={isMobile ? toggleMobileDropdown : undefined}
              className={`ml-1 w-4 h-4 inline transform transition-transform duration-300 cursor-pointer ${
                isMobile && mobileDropdownOpen ? "rotate-180" : "rotate-0"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
          {(!isMobile && dropdownVisible) ||
          (isMobile && mobileDropdownOpen) ? (
            <div className="absolute left-0 mt-2 w-48 bg-white border rounded-md shadow-lg z-50">
              {renderDropdownItems(item.subPages)}
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <a
        key={item.name}
        href={item.href}
        className={`${
          isMobile
            ? "block text-gray-800 hover:text-blue-600"
            : "text-gray-800 hover:text-blue-600"
        }`}
      >
        {item.name}
      </a>
    );
  };

  return (
    <header className="bg-white shadow-md z-50">
      <div className="container mx-auto px-6 py-3 flex justify-between items-center">
        {/* Logo Section */}
        <a href="/" className="flex items-center">
          <img src={logo} alt="Skills Plus Academy Logo" className="h-20" />
        </a>

        {/* Hamburger Icon for Mobile */}
        <button
          className="md:hidden text-gray-800 focus:outline-none"
          onClick={toggleMobileMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        {/* Navigation Links for Desktop */}
        <nav className="hidden md:flex flex-1 justify-center space-x-6">
          {navigation.map((item) => renderNavItem(item))}
        </nav>

        {/* CTA Button for Desktop */}
        <a
          href={APPLY_FORM_URL}
          target="_blank"
          className="hidden md:block bg-blue-600 text-white px-4 py-2 rounded font-semibold hover:bg-blue-700"
        >
          Apply Now
        </a>
      </div>

      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="md:hidden z-50">
          <nav className="px-6 pt-4 pb-2 space-y-2 bg-white border-t">
            {navigation.map((item) => renderNavItem(item, true))}
            <a
              href={APPLY_FORM_URL}
              target="_blank"
              className="block bg-blue-600 text-white px-4 py-2 rounded font-semibold hover:bg-blue-700"
            >
              Apply Now
            </a>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Navbar;
