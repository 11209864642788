import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import "./index.css";
import { Helmet } from "react-helmet";

// Lazy load components with error handling
const lazyLoad = (path) =>
  lazy(() =>
    import(`${path}`).catch((error) => {
      console.error(`Error loading component from path: ${path}`, error);
      return {
        default: () => (
          <div>
            Error loading component. Path: {path}
            <br />
            Error: {error.message}
          </div>
        ),
      };
    })
  );

// Common components
const components = {
  HeroSection: lazyLoad("./components/HeroSection"),
  InfoSection: lazyLoad("./components/InfoSection"),
  HighlightTicker: lazyLoad("./components/HighlightTicker"),
  CoursesOverview: lazyLoad("./components/CoursesOverview"),
  MissionStatement: lazyLoad("./components/MissionStatement"),
  PartnerSites: lazyLoad("./components/PartnerSites"),
  Footer: lazyLoad("./components/Footer"),
};

// Main pages
const pages = {
  Courses: lazyLoad("./pages/Courses"),
  AboutUs: lazyLoad("./pages/AboutUs"),
  NotFound: lazyLoad("./pages/NotFound"),
  Contact: lazyLoad("./pages/Contact"),
};

// Course pages configuration for dynamic routing
const coursePagesConfig = [
  {
    path: "/courses/adult-care",
    component: lazyLoad("./pages/courses/adultcare/AdultCare"),
  },
  {
    path: "/courses/level2-diploma-adult-social-care",
    component: lazyLoad(
      "./pages/courses/adultcare/Level2AdultSocialCareCertificate"
    ),
  },
  {
    path: "/courses/adult-care/level2-diploma-in-care",
    component: lazyLoad("./pages/courses/adultcare/Level2DiplomaInCare"),
  },
  {
    path: "/courses/adult-care/level3-diploma-in-healthcare-support",
    component: lazyLoad(
      "./pages/courses/adultcare/Level3DiplomaInHealthcareSupport"
    ),
  },
  {
    path: "/courses/adult-care/level3-certificate-in-adult-social-care",
    component: lazyLoad("./pages/courses/adultcare/Level3CertAdultSocialCare"),
  },
  {
    path: "/courses/aet-and-cava",
    component: lazyLoad("./pages/courses/aet/AETAndCAVA"),
  },
  {
    path: "/courses/aet-and-cava/level3-award-in-education-and-training",
    component: lazyLoad(
      "./pages/courses/aet/Level3AwardInEducationAndTraining"
    ),
  },
  {
    path: "/courses/aet-and-cava/level3-certificate-in-assessing-vocational-achievement",
    component: lazyLoad(
      "./pages/courses/aet/Level3CertAssessingVocationalAchievement"
    ),
  },
  {
    path: "/courses/aet-and-cava/combined-aet-and-cava",
    component: lazyLoad("./pages/courses/aet/AETandCAVACombinedOverview"),
  },
  {
    path: "/courses/business-administration",
    component: lazyLoad("./pages/courses/business/BusinessAdministration"),
  },
  {
    path: "/courses/business-administration/level1-certificate-business-administration",
    component: lazyLoad(
      "./pages/courses/business/Level1CertificateInBusinessAdministration"
    ),
  },
  {
    path: "/courses/business-administration/level2-diploma-business-administration",
    component: lazyLoad(
      "./pages/courses/business/Level2DiplomaInBusinessAdministration"
    ),
  },
  {
    path: "/courses/business-administration/level3-diploma-business-administration",
    component: lazyLoad(
      "./pages/courses/business/Level3DiplomaInBusinessAdministration"
    ),
  },
  {
    path: "/courses/customer-service",
    component: lazyLoad("./pages/courses/customercare/CustomerService"),
  },
  {
    path: "/courses/customer-service/level2-certificate-in-principles-of-customer-service",
    component: lazyLoad(
      "./pages/courses/customercare/Level2CertificateInPrinciplesOfCustomerService"
    ),
  },
  {
    path: "/courses/customer-service/level3-certificate-in-principles-of-customer-service",
    component: lazyLoad(
      "./pages/courses/customercare/Level3CertificateInPrinciplesOfCustomerService"
    ),
  },
  {
    path: "/courses/childcare",
    component: lazyLoad("./pages/courses/childcare/Childcare"),
  },
  {
    path: "/courses/childcare/level2-certificate-introducing-caring-for-children",
    component: lazyLoad(
      "./pages/courses/childcare/Level2CertificateInCaringForChildrenAndYoungPeople"
    ),
  },
  {
    path: "/courses/childcare/level2-diploma-introducing-caring-for-children",
    component: lazyLoad(
      "./pages/courses/childcare/Level2DiplomaInCaringForChildrenAndYoungPeople"
    ),
  },
  {
    path: "/courses/childcare/level3-award-preparing-to-work-in-home-based-childcare",
    component: lazyLoad(
      "./pages/courses/childcare/Level3AwardInPreparingToWorkInHomeBasedChildcare"
    ),
  },
  {
    path: "/courses/functional-skills",
    component: lazyLoad("./pages/courses/FunctionalSkills/FunctionalSkills"),
  },
  {
    path: "/courses/functional-skills/entry-level-1-functional-skills-qualification-in-english",
    component: lazyLoad(
      "./pages/courses/FunctionalSkills/EntryLevel1FunctionalSkillsEnglish"
    ),
  },
  {
    path: "/courses/functional-skills/entry-level-2-functional-skills-qualification-in-english",
    component: lazyLoad(
      "./pages/courses/FunctionalSkills/EntryLevel2FunctionalSkillsEnglish"
    ),
  },
  {
    path: "/courses/functional-skills/entry-level-3-functional-skills-qualification-in-english",
    component: lazyLoad(
      "./pages/courses/FunctionalSkills/EntryLevel3FunctionalSkillsEnglish"
    ),
  },
  {
    path: "/courses/first-aid",
    component: lazyLoad("./pages/courses/FirstAid/FirstAid"),
  },
  {
    path: "/courses/first-aid/level3-award-in-emergency-first-aid-at-work",
    component: lazyLoad(
      "./pages/courses/FirstAid/Level3AwardInEmergencyFirstAid"
    ),
  },
  {
    path: "/courses/first-aid/level3-award-in-paediatric-first-aid",
    component: lazyLoad(
      "./pages/courses/FirstAid/Level3AwardInPaediatricFirstAid"
    ),
  },
  {
    path: "/courses/health-and-socialcare",
    component: lazyLoad("./pages/courses/HealthSocialCare/HealthSocialCare"),
  },
  {
    path: "/courses/health-and-socialcare/level2-certificate-health-social-care",
    component: lazyLoad(
      "./pages/courses/HealthSocialCare/Level2CertificateInHealthAndSocialCare"
    ),
  },
  {
    path: "/courses/health-and-socialcare/level2-extended-diploma-health-social-care",
    component: lazyLoad(
      "./pages/courses/HealthSocialCare/Level2ExtendedDiplomaInHealthAndSocialCare"
    ),
  },
  {
    path: "/courses/health-and-socialcare/level3-certificate-health-social-care",
    component: lazyLoad(
      "./pages/courses/HealthSocialCare/Level3ExtendedDiplomaInHealthAndSocialCare"
    ),
  },
  {
    path: "/courses/ielts",
    component: lazyLoad("./pages/courses/IELTSB1/IELTSB1"),
  },
  {
    path: "/courses/information-technology",
    component: lazyLoad(
      "./pages/courses/InformationTechnology/InformationTechnology"
    ),
  },
  {
    path: "/courses/information-technology/level2-cert-in-digital",
    component: lazyLoad(
      "./pages/courses/InformationTechnology/Level2CertificateInDigitalSkillsForWork"
    ),
  },
  {
    path: "/courses/information-technology/level2-certificate-in-it",
    component: lazyLoad(
      "./pages/courses/InformationTechnology/Level2CertificateInITUserSkills"
    ),
  },
  {
    path: "/courses/information-technology/level2-diploma-in-it",
    component: lazyLoad(
      "./pages/courses/InformationTechnology/Level2DiplomaInITUserSkills"
    ),
  },
];

// Generate course routes dynamically
const CourseRoutes = () =>
  coursePagesConfig.map(({ path, component: Component }) => (
    <Route
      key={path}
      path={path}
      element={
        <ErrorBoundary>
          <Component />
        </ErrorBoundary>
      }
    />
  ));

// Error Boundary Component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong. Please try refreshing the page.</div>;
    }

    return this.props.children;
  }
}

function App() {
  return (
    <Router>
      <Helmet>
        <title>Home - Skills Plus Academy</title>
        <meta
          name="description"
          content="Empower your future with Skills Plus Academy's expert-led courses. Explore a wide range of courses tailored to advance your career."
        />
        <meta
          name="keywords"
          content="Skills Plus Academy, education, courses, career advancement"
        />
        <meta property="og:title" content="Home - Skills Plus Academy" />
        <meta
          property="og:description"
          content="Empower your future with Skills Plus Academy's expert-led courses."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.skillsplusacademy.org.uk/"
        />
      </Helmet>

      <Navbar />
      <ErrorBoundary>
        <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <ErrorBoundary>
                    <components.HighlightTicker />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <components.HeroSection />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <components.InfoSection />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <components.CoursesOverview />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <components.MissionStatement />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <components.PartnerSites />
                  </ErrorBoundary>
                </>
              }
            />
            <Route
              path="/courses"
              element={
                <ErrorBoundary>
                  <pages.Courses />
                </ErrorBoundary>
              }
            />
            {CourseRoutes()}
            <Route
              path="/aboutus"
              element={
                <ErrorBoundary>
                  <pages.AboutUs />
                </ErrorBoundary>
              }
            />
            <Route
              path="/contact"
              element={
                <ErrorBoundary>
                  <pages.Contact />
                </ErrorBoundary>
              }
            />
            <Route
              path="*"
              element={
                <ErrorBoundary>
                  <pages.NotFound />
                </ErrorBoundary>
              }
            />
          </Routes>
          <ErrorBoundary>
            <components.Footer />
          </ErrorBoundary>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
